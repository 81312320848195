<template>
  <base-button
               class="ml-2 mr-3"
               color="primary"
               :loading="testing"
               @click="testEmail(email)">Test</base-button>
</template>

<script>
import emailApi from '@/api/monitorNotifications/emailApi'

export default {
  props: {
    general: {
      required: true,
      default: false,
      type: Boolean
    },
    email: {
      required: true,
      type: String || Object
    }
  },

  data () {
    return {
      testing: false,
      errorMessage: ''
    }
  },

  methods: {

    async testEmail (email) {
      this.errorMessage = ''
      this.testing = true
      try {
        await emailApi.testEmail(email)
      } catch (e) {
        console.error(e.response.data)
        if (typeof e.response.data !== 'undefined') {
          this.errorMessage = e.response.data.message
        }
      }
      await this.refreshCurrentUser()
      this.testing = false
      this.$emit('error', this.errorMessage)
    },
    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>
