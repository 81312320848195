<template>
  <div class="Email">
    <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
      <form v-if="general" ref="form"
            @submit.prevent="passes(onFormSubmit)">

        <div class="description">
<!--          1 represents the account owner-->
          You can specify up to {{ user.is_on_old_plan ? 5 : 1 + (user.limits ? user.limits.notify_only_seats : user.subscription_plan.notify_only_seats)}} email addresses to which we will send notifications
        </div>
        <base-alert type="error" v-if="generalError && generalHyperlink">
          The following monitor exceeded the maximum number of emails: <a :href=generalHyperlink>{{generalError}}</a>
        </base-alert>
        <base-alert type="error" v-else-if="generalError">
          {{generalError}}
        </base-alert>
        <base-alert type="error" v-if="testError">
          {{testError}}
        </base-alert>
        <div class="email-list">
          <div class="email"
               v-for="(channel, index) in userGeneralNotificationChannels"
               :key="index">
            <div class="remove"
                 @click.prevent="deleteGeneralChannel(channel)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text" style="margin-right: 10px">{{ channel.item_value }}</div>
            <email-test :general="general" @error="testingError" :email="channel.item_value"></email-test>
            <p class="text">You have {{user.tests_per_day}} tests.</p>
          </div>
        </div>

        <div class="new-email">
          <base-textbox ref="newEmail"
                        placeholder="Email Address"
                        rules="email"
                        v-model="newEmail" />
          <base-button class="add"
                       color="primary"
                       :disabled="emailAlreadyAdded || !newEmail || invalid"
                       :loading="submitting"
                       @click.prevent="onFormSubmit">Add Email</base-button>
        </div>
      </form>
      <form v-else ref="form"
            @submit.prevent="passes(onFormSubmit)">
        <div class="description">
<!--          1 represents the account owner-->
          You can specify up to {{ user.is_on_old_plan ? 5 : 1 + (user.limits ? user.limits.notify_only_seats : user.subscription_plan.notify_only_seats)}} email addresses to which we will send notifications for <monitor-label :monitor="monitor" :show-favicon="false" /> in case of something went wrong
        </div>

        <base-alert type="warning" v-if="channels.length === 0">
          You have not specified any emails so we will not alert you via email
        </base-alert>

        <base-alert type="error" v-if="emailAddError" class="mt-3">
          We could not add the email
        </base-alert>

        <base-alert type="error" v-if="testError">
          {{testError}}
        </base-alert>

        <div class="email-list">
          <div class="email"
               v-for="(channel, index) in channels"
               :key="index">
            <div class="remove"
                 @click.prevent="deleteChannel(channel)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text" style="margin-right: 10px">{{ channel.email }}</div>
            <email-test :general="general" @error="testingError" :email="channel.email"></email-test>
            <p class="text">You have {{user.tests_per_day}} tests.</p>
          </div>
        </div>
        <!--          1 represents the account owner-->
        <div class="new-email" v-if="channels.length < (user.is_on_old_plan ? 5 : (1 + (user.limits ? user.limits.notify_only_seats : user.subscription_plan.notify_only_seats)))">
          <base-textbox ref="newEmail"
                        placeholder="Email Address"
                        rules="email"
                        v-model="newEmail" />
          <base-button class="add"
                       color="primary"
                       :disabled="emailAlreadyAdded || !newEmail || invalid"
                       :loading="submitting"
                       @click.prevent="onFormSubmit">Add Email</base-button>
        </div>

        <p class="already-added"
           v-if="emailAlreadyAdded">This email is already added</p>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'

import notificationChannelsApi from '@/api/monitorNotifications/notificationChannelsApi.js'
import emailApi from '@/api/monitorNotifications/emailApi.js'
import usersApi from '../../../api/usersApi'
import EmailTest from '@/components/Monitor/Notifications/EmailTest.vue'

export default {
  components: {
    EmailTest,
    MonitorLabel
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      channels: [],

      newEmail: '',
      emailAlreadyAdded: false,

      submitting: false,
      emailAddError: false,
      userGeneralNotificationChannels: null,
      generalError: false,
      generalHyperlink: false,
      testError: ''
    }
  },

  created () {
    if (this.general) {
      this.loadGeneralNotificationChannels()
    }
  },

  mounted () {
    this.flushNewHookFocus()
  },

  methods: {
    async onFormSubmit () {
      if (this.general) {
        this.submitting = true

        try {
          await emailApi.generalStore(this.newEmail)
          await this.loadGeneralNotificationChannels()
          this.newEmail = ''
        } catch (e) {
          this.emailAddError = true
          this.generalError = e.response.data.errorMonitor ?? 'Something went wrong'
          this.generalHyperlink = e.response.data.hyperlink ?? false
        }

        this.submitting = false
      } else {
        this.emailAddError = false
        if (this.emailAlreadyAdded) {
          return false
        }

        this.submitting = true

        try {
          await emailApi.store(this.monitor.id, this.newEmail)
          this.$emit('update')
          this.newEmail = ''
        } catch (e) {
          this.emailAddError = true
        }

        this.submitting = false

        this.flushNewHookFocus()
      }
    },

    async deleteChannel (channel) {
      await notificationChannelsApi.delete(channel.id)
      this.$emit('update')
    },

    async deleteGeneralChannel (channel) {
      await notificationChannelsApi.generalDelete(channel.id)
      await this.loadGeneralNotificationChannels()
    },

    flushNewHookFocus () {
      if (this.$refs.newEmail) {
        this.$refs.newEmail.focus()
      }
    },

    async loadGeneralNotificationChannels () {
      try {
        this.userGeneralNotificationChannels = await usersApi.getGeneralChannels('mail')
      } catch (e) {
        this.generalError = 'Something went wrong'
      }
    },

    testingError (value) {
      this.testError = value
    }
  },

  watch: {
    monitor: {
      handler: function () {
        if (!this.general) {
          this.channels = this.monitor.notification_channels
            .filter(channel => channel.via === 'mail')
        }
      },
      deep: true,
      immediate: true
    },

    newEmail: {
      handler: function (newEmail) {
        if (!this.general) {
          this.emailAlreadyAdded = false

          this.channels.forEach(channel => {
            if (channel.email === newEmail) {
              this.emailAlreadyAdded = true
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>

<style lang="scss" scoped>
  .Email {
    max-width: 550px;

    .description {
      margin-bottom: 10px;
      color: #777;
      line-height: 1.5;
    }

    .email-list {
      max-width: 550px;
      align-items: center;

      .email {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .remove {
          cursor: pointer;
          padding: 10px 10px 10px 0;

          &:hover /deep/ svg g {
            transition: 0.2s all ease;
            fill: map-get($colors, purple-1) !important;
          }
        }

        .text {
          margin-bottom: 5px;
        }
      }
    }

    .new-email {
      display: flex;

      .base-text-box {
        flex-grow: 4;
        margin: 0;
      }

      .add {
        margin: 0 0 0 10px;
      }
    }

    .button-update {
      width: 200px;
    }

    .already-added {
      margin-top: 10px;
    }
  }
</style>
