export default {
  store (monitorId, email) {
    return axios.post(`/monitors/${monitorId}/notifications/email`, {
      email
    })
  },

  generalStore (email) {
    return axios.post(`/monitors/general/notifications/email`, {
      email
    })
  },

  testEmail (email) {
    return axios.post(`/monitors/notifications/test-email`, {
      email
    })
  }
}
