<template>
  <div class="NotificationsEmail">
    <div class="top">
      <div class="title">Email Notifications</div>
    </div>
    <div class="card">
      <div class="card-body">
        <email-notifications :monitor="monitor"
                             :general="general"
                             @update="$emit('update')"/>
      </div>
    </div>
  </div>
</template>

<script>
import EmailNotifications from '@/components/Monitor/Notifications/Email.vue'

export default {
  components: {
    EmailNotifications
  },

  metaInfo: {
    title: 'Email Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
  .NotificationsEmail {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }
    }
  }
</style>
